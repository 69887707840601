<template>
  <div class="container">
    <heading-with-button :heading="$t(`configurator.heading.${mode}`)" />
    <div class="bg-white">
      <breadcrumbs
        :items="steps"
        :current-item="currentStep"
        :enabled-steps="nextStepIdx"
        @item-click="navigate"
      />
      <div v-if="isLoading" class="p-b-xxl p-t-xxl">
        <spinner :color="configuratorData.PrimaryColor" class="align-center" />
      </div>
      <div v-if="!isLoading">
        <div v-if="currentStep === 'customerTypeSelection'">
          <customer-type-selection
            :customerTypes="customerTypes"
            @customerType-selected="onCustomerTypeSelected"
          />
        </div>
        <div v-if="currentStep === 'eventTypeSelection'">
          <event-type-selection
            :eventTypes="eventTypes"
            @eventType-selected="onEventTypeSelected"
          />
        </div>
        <div v-if="currentStep === 'groupDataSelection'">
          <group-data-selection
            :products="products"
            :peopleCount="order.details.peopleCount"
            :playDate="order.details.playDate"
            :playTime="order.details.playTime"
            @form-data-filled="onGroupDataChanged"
            @form-data-submit="onGroupDataSelected"
          />
        </div>
        <div v-if="currentStep === 'productSelection'">
          <product-selection
            :products="filteredProducts"
            :peopleCount="order.details.peopleCount"
            @product-selected="onProductSelected"
          />
        </div>
        <div v-if="currentStep === 'extraSelection'">
          <extra-selection
            :extras="extras"
            :selected-extras="order.extras"
            @extras-selected="onExtrasSelected"
          />
        </div>
        <div v-if="currentStep === 'checkout'">
          <customer-form
            :form-fields="customerPersonalDataForm"
            :orderData="orderData"
            :mode="mode"
            @extras-selected="onExtrasSelected"
            @form-data-filled="onSubmit"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import spinner from '@/components/common/spinner';
import headingWithButton from '@/components/headingWithButton.vue';
import breadcrumbs from '@/components/common/breadcrumbs/breadcrumbs.vue';
import customerTypeSelection from '@/components/configuratorParts/customerTypeSelection.vue';
import eventTypeSelection from '@/components/configuratorParts/eventTypeSelection.vue';
import groupDataSelection from '@/components/configuratorParts/groupDataSelection.vue';
import productSelection from '@/components/configuratorParts/productSelection.vue';
import extraSelection from '@/components/configuratorParts/extraSelection.vue';
import customerForm from '@/components/configuratorParts/customerForm.vue';
import customerPersonalDataForm from '@/config/customerPersonalDataForm';

function fixOrderTime(details, times) {
  const playTime = times
    .find((t) => t.FrontendValue === details.playTime)
    ?.BookableTime.match(/^\d\d:\d\d/)[0];

  if (playTime) {
    return { ...details, playTime };
  }
  return details;
}

export default {
  name: 'configurator',

  components: {
    spinner,
    headingWithButton,
    breadcrumbs,
    customerTypeSelection,
    eventTypeSelection,
    groupDataSelection,
    productSelection,
    extraSelection,
    customerForm,
  },

  watch: {
    /**
     * Fetches products if customerType or eventType gets updated.
     */
    async selectedCustomerTypeId() {
      await this.updateEventTypes();
      await this.updateProducts();
    },

    async selectedEventTypeId() {
      await this.updateProducts();
    },

    /**
     * Fetches extras based on user product selection.
     */
    async selectedProductId() {
      this.isLoading = true;
      this.removeSelectedExtras();
      await Promise.all([this.fetchExtras({ productId: this.selectedProductId })]);
      this.isLoading = false;
    },
  },

  data() {
    return {
      isLoading: false,
      mode: '',
      steps: [
        {
          id: 'customerTypeSelection',
          name: this.$t('configurator.chooseCustomerType'),
        },
        {
          id: 'eventTypeSelection',
          name: this.$t('configurator.chooseEventType'),
        },
        {
          id: 'groupDataSelection',
          name: this.$t('configurator.chooseGroupData'),
        },
        {
          id: 'productSelection',
          name: this.$t('configurator.chooseProduct'),
        },
        {
          id: 'extraSelection',
          name: this.$t('configurator.extras'),
        },
        {
          id: 'checkout',
          name: this.$t('configurator.checkoutEmojis'),
        },
      ],
      currentStep: '',
      nextStepIdx: 0,
      selectedCustomerTypeId: '',
      selectedEventTypeId: '',
      selectedProductId: '',
      customerPersonalDataForm,
    };
  },

  computed: {
    ...mapGetters({
      configuratorData: 'configurators/getConfiguratorData',
      customerTypes: 'configurators/getCustomerTypes',
      eventTypes: 'configurators/getEventTypes',
      products: 'configurators/getProducts',
      extras: 'configurators/getExtras',
      order: 'configurators/getOrder',
    }),

    /**
     * Obtains the IDs of the selected items from the order store and finds the matching items
     * in the respective store. In this way we avoid double data storage.
     */
    orderData() {
      return {
        details: this.order.details,
        customer: this.customerTypes.find((t) => t.id === this.order.customerType) || {},
        product: this.products.find((e) => e.id === this.order.product) || {},
        extras: this.order.extras.map((e) => this.extras.find((extra) => extra.id === e)),
      };
    },

    filteredProducts() {
      const { peopleCount, playTime } = this.order.details;
      return this.products.filter(
        ({ MinParticipants, MaxParticipants, BookingTimes }) =>
          (MinParticipants === undefined || peopleCount >= MinParticipants) &&
          (MaxParticipants === undefined || peopleCount <= MaxParticipants) &&
          BookingTimes.find(({ BookableTime, FrontendValue }) =>
            FrontendValue
              ? FrontendValue === playTime
              : BookableTime.match(/^\d\d:\d\d/)[0] === playTime,
          ),
      );
    },
  },

  beforeMount() {
    const { productId, customerType } = this.$route.query;

    if (productId) {
      this.onProductSelected(productId);
      return;
    }
    if (customerType) {
      this.onCustomerTypeSelected(customerType);
      return;
    }
    this.currentStep = 'customerTypeSelection';
  },

  async mounted() {
    this.isLoading = true;
    this.setBookingMode(this.$route.query.mode);
    await this.fetchCustomerTypes(/* { configuratorId: this.configuratorData.id } */);
    this.isLoading = false;
  },

  methods: {
    ...mapActions('configurators', {
      fetchCustomerTypes: 'fetchCustomerTypes',
      fetchEventTypes: 'fetchEventTypes',
      fetchProducts: 'fetchProducts',
      fetchExtras: 'fetchExtras',
      postOrder: 'postOrder',
      setSelectedCustomerType: 'setSelectedCustomerType',
      setSelectedEventType: 'setSelectedEventType',
      setSelectedProduct: 'setSelectedProduct',
      setSelectedExtras: 'setSelectedExtras',
      setOrderDetails: 'setOrderDetails',
      removeSelectedProduct: 'removeSelectedProduct',
      removeSelectedExtras: 'removeSelectedExtras',
    }),

    setNextStep(idx) {
      this.nextStepIdx = Math.max(this.nextStepIdx, idx);
    },

    resetNextStep(idx) {
      this.nextStepIdx = Math.min(this.nextStepIdx, idx);
    },

    async updateEventTypes() {
      const customerTypeId = this.selectedCustomerTypeId;
      this.isLoading = true;
      await this.fetchEventTypes({ customerTypeId });
      this.isLoading = false;
    },

    async updateProducts() {
      const customerTypeId = this.selectedCustomerTypeId;
      const eventTypeId = this.selectedEventTypeId;
      if (customerTypeId && eventTypeId) {
        this.removeSelectedProduct();
        this.removeSelectedExtras();
        this.isLoading = true;
        await this.fetchProducts({ customerTypeId, eventTypeId });
        this.resetNextStep(3);
        this.isLoading = false;
      }
    },

    navigate(to) {
      // early exit
      if (this.currentStep === to) return;
      // if the clicked step is checkout, navigate to the latest step that is still empty
      if (to === 'checkout') {
        this.currentStep = this.steps[this.nextStepIdx].id;
        return;
      }

      this.currentStep = to;
    },

    setBookingMode(mode) {
      switch (mode) {
        case 'booking':
          this.mode = 'booking';
          break;
        case 'inquiry':
          this.mode = 'inquiry';
          break;
        default:
          this.mode = 'configurator';
      }
    },

    onCustomerTypeSelected(customerTypeId) {
      this.selectedCustomerTypeId = customerTypeId;
      this.setSelectedCustomerType({ customerTypeId });
      this.currentStep = 'eventTypeSelection';
      this.setNextStep(1);
    },

    onEventTypeSelected(eventTypeId) {
      this.selectedEventTypeId = eventTypeId;
      this.setSelectedEventType({ eventTypeId });
      this.currentStep = 'groupDataSelection';
      this.setNextStep(2);
    },

    onGroupDataChanged(formData) {
      this.setOrderDetails({ formData });
      const { peopleCount, playDate, playTime } = this.order.details;
      if (peopleCount && playDate && playTime) {
        this.setNextStep(3);
      } else {
        this.resetNextStep(2);
      }
    },

    onGroupDataSelected() {
      this.currentStep = 'productSelection';
    },

    onProductSelected(productId) {
      this.selectedProductId = productId;
      this.setSelectedProduct({ productId });
      this.currentStep = 'extraSelection';
      this.setNextStep(4);
    },

    onExtrasSelected(selectedExtras) {
      this.isLoading = true;
      this.setSelectedExtras({ selectedExtras });
      this.currentStep = 'checkout';
      this.isLoading = false;
    },

    async onSubmit(formData, priceTotal, pricePerPerson) {
      this.isLoading = true;
      this.setOrderDetails({ formData });
      await this.postOrder({
        configuratorId: this.configuratorData.id,
        details: fixOrderTime(this.order.details, this.orderData.product.BookingTimes),
        orderValue: priceTotal,
        customerType: this.order.customerType,
        eventType: this.order.eventType,
        selectedProduct: this.order.product,
        selectedExtras: this.order.extras,
      });
      this.trackConversion({
        zip: this.orderData.details.zipCode,
        pricePerPerson,
        peopleCount: this.orderData.details.peopleCount,
        cartValue: priceTotal,
        value: priceTotal,
        /*
         *  don't change next line without contact to
         *  Timo (tb@tibacreative.de) due some gtm problems
         */
        event: this.orderData.details.isRequest ? 'inquiry' : 'sale',
        submissionType: this.orderData.details.isRequest ? 'inquiry' : 'sale',
        customerType: this.orderData.customer.Name,
        product: this.orderData.product.Name,
      });
      this.$router.push({ name: 'thank-you' });
      this.isLoading = false;
      // todo: reset store here
    },

    trackConversion(layerData) {
      this.$gtm.trackEvent({
        category: 'Configurator',
        ...layerData,
      });
    },
  },
};
</script>
