import styled from 'vue-styled-components';

/**
 * Renders a checkbox, with the exception that it must be specified as a property.
 */
const checkbox = styled('input')`

  appearance: none;
  width: 2rem;
  height: 2rem;
  border: none;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 2rem;
    height: 2rem;
    border-radius: 1rem;
    border: 0.2rem solid ${props => props.theme.lightGrey};
    transition: 0.25s ease all;
  }

  &:checked {
    &:before {
      border-color: ${props => props.theme.primaryColor};
    }

    &:after {
      content: '✔';
      position: absolute;
      top: 0.15rem;
      left: 0.4rem;
      color: ${props => props.theme.primaryColor};
      font-size: 1.5rem;
      animation: zoomIn 0.5s;
    }
  }

  &:focus, &:checked {
    outline: none;
  }

  &:focus {
    transform: scale(1.1);
  }

  @keyframes zoomIn {
    0% {
      transform: scale(0,0);
    }
    50% {
      transform: scale(1.5,1.5);
    }
    100% {
      transform: scale(1,1);
    }
  }
`;

export { checkbox as default };
