/**
 * This is a customer form configuration object. It should simplfy form components.
 * required - true when form field is required
 * type - type of the form field
 * label - i18n key for the label
 * placeholder - optional, i18 key for the placeholder
 */

import { EMAIL_PATTERN, STREET_PATTERN, ZIP_PATTERN } from '@/utils/patterns';

const customerPersonalDataForm = {
  // businessCustomer: {
  //   required: false,
  //   type: 'checkbox',
  //   label: 'forms.businessCustomerCheck',
  //   placeholder: '',
  //   className: 'col-12',
  // },
  firstName: {
    required: true,
    type: 'text',
    label: 'forms.firstName',
    placeholder: '',
    className: 'col-3',
  },
  lastName: {
    required: true,
    type: 'text',
    label: 'forms.lastName',
    placeholder: '',
    className: 'col-3',
  },
  emailAdress: {
    required: true,
    type: 'email',
    label: 'forms.email',
    pattern: EMAIL_PATTERN,
    placeholder: '',
    className: 'col-3',
  },
  phoneNumber: {
    required: true,
    type: 'tel',
    label: 'forms.phone',
    placeholder: '',
    className: 'col-3',
  },
  company: {
    required: false,
    type: 'text',
    label: 'forms.company',
    placeholder: '',
    className: 'col-3',
  },
  street: {
    required: true,
    type: 'text',
    label: 'forms.street',
    pattern: STREET_PATTERN,
    placeholder: '',
    className: 'col-4',
  },
  zipCode: {
    required: true,
    type: 'text',
    label: 'forms.zip',
    pattern: ZIP_PATTERN,
    title: 'forms.zipTitle',
    placeholder: '',
    className: 'col-2',
  },
  city: {
    required: true,
    type: 'text',
    label: 'forms.city',
    placeholder: '',
    className: 'col-3',
  },
  personalMessage: {
    required: false,
    type: 'textarea',
    label: 'forms.message',
    placeholder: '',
    className: 'col-12',
  },
  agbConfirmation: {
    required: true,
    type: 'checkbox',
    label: 'forms.agb',
    placeholder: '',
    className: 'col-12',
    link: '/agb/',
  },
  dsgvo: {
    required: true,
    type: 'checkbox',
    label: 'forms.dsgvoMessage',
    placeholder: '',
    link: '/datenschutz/',
  },
};

export { customerPersonalDataForm as default };
