<template>
  <div class="p-lg">
    <h2 class="m-b-lg">{{ $t('configurator.checkoutMessage') }}</h2>
    <div class="flex">
      <card class="col-8 p-md">
        <card v-if="errors.length" class="m-xs p-xs bg-red font-white">
          <b class="block m-b-xs">{{ $t('forms.requiredFields') }}</b>
          <span v-for="fieldKey in errors" :key="fieldKey" class="block m-b-xs">
            <span v-html="$t(formFields[fieldKey].label)" />
          </span>
        </card>
        <form class="flex" @submit.prevent="submit(formFields, inputData, isRequest)">
          <div
            v-for="(formField, name) in formFields"
            :key="name"
            :class="`${formField.className} p-xs`"
          >
            <input
              v-if="formField.type === 'checkbox'"
              type="checkbox"
              v-model="inputData[name]"
              :id="name"
              class="float-left"
            />
            <label :for="name" class="m-b-xxs block">
              <!--ToDo: We should get those links by an api call -->
              <span v-html="$t(formField.label, { link: formField.link })"></span>
            </label>
            <styled-input
              v-if="isInput(formField.type)"
              :type="formField.type"
              :name="name"
              :pattern="formField.pattern || false"
              :title="$t(formField.title) || false"
              v-model="inputData[name]"
              :required="formField.required"
              :id="name"
            />
            <styled-textarea v-if="formField.type === 'textarea'" v-model="inputData[name]" />
          </div>
          <styled-button fullWidth type="submit">
            {{ isRequest ? $t('forms.sendRequest') : $t('forms.bookNow') }}
          </styled-button>
          <!--          <div class="m-t-lg" v-if="mode === 'configurator'">
            <input
              type="checkbox"
              v-model="isRequest"
              id="request"
              class="float-left"
            />
            <label for="request">{{$t('configurator.request')}}</label>
          </div>-->
        </form>
      </card>
      <div class="col-4 p-l-md">
        <card class="p-md" theme-background>
          <H2 class="p-b-md">{{ $t('configurator.summary') }}</H2>
          <table class="table">
            <tr>
              <td>{{ orderData.product.Name }}</td>
              <td>{{ formatPrice(getPrice(orderData.product, orderData.details.peopleCount)) }}</td>
            </tr>
            <tr>
              <td>{{ $t('configurator.groupSize') }}</td>
              <td>{{ orderData.details.peopleCount }} Personen</td>
            </tr>
            <tr>
              <td>{{ $t('configurator.dateAndTime') }}</td>
              <td>{{ playDateAndTime }}</td>
            </tr>
          </table>
          <hr v-if="orderData.extras.length" />
          <table class="table -extras" v-if="orderData.extras.length">
            <thead>
              <tr>
                <th colspan="3">{{ $t('configurator.extras') }}</th>
              </tr>
            </thead>
            <tr v-for="extra in orderData.extras" :key="extra.id">
              <td class="removable">
                <button @click="removeExtra(extra.id)">
                  <icon name="times" />
                </button>
              </td>
              <td>{{ extra.Name }}</td>
              <td>{{ extra.PriceType === 'Flat' ? 'pauschal' : 'p.P.' }}</td>
              <td>{{ formatPrice(extra.Price) }}</td>
            </tr>
          </table>
          <hr />
          <table class="table">
            <tr class="font-xl">
              <td>
                <b>{{ $t('configurator.pricePerPerson') }}</b>
              </td>
              <td>
                <b>{{ formatPrice(pricePerPerson) }}</b>
              </td>
            </tr>
            <tr>
              <td>{{ $t('configurator.total') }}</td>
              <td>{{ formatPrice(priceTotal) }}</td>
            </tr>
            <tr>
              <td colspan="2">{{ $t('configurator.tax', { tax: formatPrice(taxTotal) }) }}</td>
            </tr>
            <tr v-if="shippingCosts">
              <td colspan="2">
                {{ $t('configurator.shipping', { shippingCosts: formatPrice(shippingCosts) }) }}
              </td>
            </tr>
          </table>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import icon from 'vue-awesome/components/Icon.vue';
import styledTextarea from '@/components/common/styledTextarea';
import styledInput from '@/components/common/styledInput';
import styledButton from '@/components/common/styledButton';
import card from '@/components/common/card';
import { isInput } from '@/utils/components';
import { getPrice, formatPrice, getTotal, getTax } from '@/utils/price';

export default {
  name: 'customer-form',

  components: {
    styledInput,
    styledTextarea,
    styledButton,
    card,
    icon,
  },

  computed: {
    playDateAndTime() {
      const { playDate, playTime } = this.orderData.details;
      const time = playTime?.match(/^\d\d:\d\d/)?.[0] ?? '';
      const date = playDate?.replace(/-/g, '/') ?? '';

      if (time) {
        return new Date(`${date} ${time}`).toLocaleString(undefined, {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        });
      }

      return `${new Date(date).toLocaleString(undefined, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      })} ${playTime}`;
    },

    items() {
      return [this.orderData.product, ...this.orderData.extras];
    },

    shippingCosts() {
      return this.items.find((item) => item.ShippingCosts) ? 4.9 : 0;
    },

    priceTotal() {
      const { peopleCount } = this.orderData.details;
      return this.items.reduce((sum, item) => sum + getTotal(item, peopleCount), 0);
    },

    pricePerPerson() {
      return this.priceTotal / this.orderData.details.peopleCount;
    },

    taxTotal() {
      const { peopleCount } = this.orderData.details;
      const tax = this.items.reduce((sum, item) => sum + getTotal(item, peopleCount, getTax), 0);
      return Math.ceil(tax * 100) / 100;
    },
  },

  props: {
    formFields: {
      type: Object,
      required: true,
    },

    orderData: {
      type: Object,
      required: true,
    },

    mode: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      inputData: {},
      isRequest: true,
      errors: [],
    };
  },

  mounted() {
    window.scroll(0, 0);
    if (this.mode === 'inquiry') {
      this.isRequest = true;
    }
  },

  methods: {
    isInput,
    getPrice,
    formatPrice,

    checkForRequiredFields(formFields, inputData) {
      return Object.keys(formFields).filter((key) => formFields[key].required && !inputData[key]);
    },

    removeExtra(extraId) {
      const filtered = this.orderData.extras
        .map((extra) => extra.id)
        .filter((id) => id !== extraId);
      this.$emit('extras-selected', filtered);
    },

    submit(formFields, inputData /* , isRequest */) {
      const errors = this.checkForRequiredFields(formFields, inputData);
      if (!errors.length) {
        this.$emit(
          'form-data-filled',
          {
            ...inputData,
            isRequest: true,
          },
          this.priceTotal,
          this.pricePerPerson,
        );
        this.errors = [];
        return;
      }
      this.errors = errors;
    },
  },
};
</script>

<style lang="scss" scoped>
.table {
  width: 100%;
  line-height: 1.25;

  th {
    text-align: left;
    font-weight: bold;
    font-size: 1.25rem;
    padding: 1rem 0 0.5rem 0;
  }

  td {
    padding: 0.5rem 0 0.5rem 1rem;

    &:first-child {
      padding-left: 0;
      width: 50%;
      word-break: break-word;
    }
    &:last-child {
      text-align: right;
    }
  }

  &.-extras {
    td {
      &:first-child {
        width: auto;
      }
      &:nth-child(2) {
        width: 50%;
        word-break: break-word;
      }
    }

    td.removable {
      > button {
        background: transparent;
        cursor: pointer;
        border: 1px solid white;
        border-radius: 100%;
        padding: 3px;
        line-height: 0;
        > * {
          color: white;
          width: 0.75rem;
          height: 0.75rem;
        }
        &:active,
        &:focus,
        &:hover {
          outline: none;
          box-shadow: 0 0 1px 2px rgba(255, 255, 255, 0.5);
        }
      }

      vertical-align: middle;
      opacity: 1;

      &:hover ~ td {
        transition: filter 100ms, opacity 100ms, text-decoration 100ms;
        filter: blur(1px);
        opacity: 0.9;
      }
      //&:active ~ td {
      //  transition: filter 500ms, opacity 500ms;
      //  filter: blur(20px);
      //  opacity: 0;
      //  height: 0;
      //}
    }
  }
}
</style>
