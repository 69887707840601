function floatToEuro(priceInEuro) {
  return priceInEuro.toLocaleString('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
  });
}

function getScalePrice(scale, people) {
  return scale.find(item => item.FromParticipant <= people && people <= item.ToParticipant)?.Price;
}

export function getPrice({ Price, IsScalePrice, ScalePrice }, peopleCount) {
  return ((IsScalePrice && getScalePrice(ScalePrice, peopleCount)) || Price);
}

export function getTax(item, peopleCount) {
  const taxRate = item.taxrates?.taxRate;
  return getPrice(item, peopleCount) / (100 + taxRate) * taxRate;
}

export function getTotal(item, peopleCount, fn = getPrice) {
  const singlePrice = fn(item, peopleCount);
  return item.PriceType === 'Flat' ? singlePrice : peopleCount * singlePrice;
}

export function formatPrice(Price, PriceType) {
  const priceString = floatToEuro(Price);
  switch (PriceType) {
    case 'Flat':
      return `${priceString} ${this.$t('configurator.flatRate')}`;
    case 'PerPerson':
      return `${priceString} ${this.$t('configurator.perPerson')}`;
    default:
      return priceString;
  }
}
