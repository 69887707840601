import styled from 'vue-styled-components';

const spinnerProps = {
  size: {
    type: String,
    default: '2.5rem',
  },
};

const spinner = styled('div', spinnerProps)`
  display: block;
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: 50%;
  border: 5px solid;
  border-color: ${props => props.theme.primaryColor} transparent;
  animation: spinningAnimation 1.5s linear infinite;

  @keyframes spinningAnimation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export { spinner as default };
