<template>
  <div class="p-lg">
    <h2 class="m-b-lg">{{ $t('configurator.chooseGroupDataLong') }}</h2>
    <card overflowVisible>
      <form class="flex overflow-visible">
        <div class="col-4 p-md">
          <label for="peopleCount" class="m-b-xxs block">{{ $t('forms.peopleCount') }}</label>
          <styled-select id="peopleCount" v-model="peopleCountFormData">
            <option
              v-for="option in generateGroupOptions(
                minParticipants,
                maxParticipants,
                $t('forms.persons'),
              )"
              :key="option.value"
              :value="option.value"
            >
              {{ option.label }}
            </option>
          </styled-select>
        </div>
        <div class="col-4 p-md">
          <label for="playDate" class="m-b-xxs block">{{ $t('forms.playDate') }}</label>
          <datepicker
            class="input-date"
            id="playDate"
            type="date"
            name="playDate"
            v-model="playDateFormData"
          />
        </div>
        <div class="col-4 p-md">
          <label for="playTime" class="m-b-xxs block">{{ $t('forms.playTime') }}</label>
          <styled-select id="playTime" v-model="playTimeFormData">
            <option v-for="time in bookableTimeOptions" :key="time.value" :value="time.value">
              {{ time.label }}
            </option>
          </styled-select>
        </div>
      </form>
      <div class="position-relative p-md">
        <styled-button
          :disabled="!(peopleCountFormData && playDateFormData && playTimeFormData)"
          fullWidth
          invert
          type="button"
          @click.prevent="$emit('form-data-submit')"
          >{{ $t('forms.next') }}</styled-button
        >
      </div>
    </card>
  </div>
</template>
<script>
import datepicker from 'vuejs-datepicker';
import card from '@/components/common/card';
import styledSelect from '@/components/common/styledSelect';
import styledButton from '@/components/common/styledButton';

function minBy(data, accessor) {
  if (!data.length) return undefined;
  return data.reduce((min, curr) => {
    const value = accessor(curr);
    if (value == null) return min;
    if (min == null) return value;
    return Math.min(min, value);
  }, undefined);
}

function maxBy(data, accessor) {
  if (!data.length) return undefined;
  return data.reduce((max, curr) => {
    const value = accessor(curr);
    if (value == null) return max;
    if (max == null) return value;
    return Math.max(max, value);
  }, undefined);
}

function getMinParticipants(products) {
  if (products.length === 0) return 1; // safety fallback for reduce
  return minBy(products, (value) => value.MinParticipants) || 1;
}

function getMaxParticipants(products) {
  if (products.length === 0) return 100; // safety fallback for reduce
  return maxBy(products, (value) => value.MaxParticipants) || 100;
}

function clearOutOfRange(count, min, max) {
  return count >= min && count <= max ? count : '';
}

function clearInvalidOption(value, options) {
  return options.find((option) => option.value === value) ? value : '';
}

function uniqueByValue(array) {
  const uniqueObj = array.reduce((acc, curr) => ({ ...acc, [curr.value]: curr }), {});
  return Object.keys(uniqueObj).map((key) => uniqueObj[key]);
}

function getBookableTimeOptions(products, timeSuffix) {
  const allTimes = products.flatMap((product) =>
    product.BookingTimes.map(({ BookableTime, FrontendValue }) => {
      const time = BookableTime.match(/^\d\d:\d\d/)?.[0];
      return {
        value: FrontendValue || time,
        label: FrontendValue || `${time} ${timeSuffix}`,
      };
    }),
  );

  return uniqueByValue(allTimes).sort((a, b) => (a.value > b.value ? 1 : -1));
}

export default {
  name: 'group-data-selection',

  components: {
    card,
    styledSelect,
    styledButton,
    datepicker,
  },

  watch: {
    peopleCountFormData() {
      this.emitFormData();
    },

    playDateFormData() {
      this.emitFormData();
    },

    playTimeFormData() {
      this.emitFormData();
    },
  },

  props: {
    products: {
      type: Array,
      default: null,
    },
    peopleCount: {
      type: String,
      default: '',
    },
    playDate: {
      Type: String,
      default: '',
    },
    playTime: {
      Type: String,
      default: '',
    },
  },

  data() {
    const minParticipants = getMinParticipants(this.products);
    const maxParticipants = getMaxParticipants(this.products);
    const bookableTimeOptions = getBookableTimeOptions(this.products, this.$t('forms.clock'));
    return {
      minParticipants,
      maxParticipants,
      bookableTimeOptions,
      peopleCountFormData: clearOutOfRange(this.peopleCount, minParticipants, maxParticipants),
      playDateFormData: this.playDate && new Date(this.playDate.replace(/-/g, '/')),
      playTimeFormData: clearInvalidOption(this.playTime, bookableTimeOptions),
    };
  },

  methods: {
    emitFormData() {
      // Todo: Fix this - we form the date here like we would expect from html date input
      const date = this.playDateFormData;
      this.$emit('form-data-filled', {
        peopleCount: this.peopleCountFormData,
        playDate: date && `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
        playTime: this.playTimeFormData,
      });
    },

    generateGroupOptions(min, max, labelSuffix) {
      const groupSizes = [];
      // eslint-disable-next-line
      for (let i = min; i <= max; i++) {
        groupSizes.push({
          label: `${i} ${labelSuffix}`,
          value: i,
        });
      }
      return groupSizes;
    },
  },
};
</script>
<style lang="scss">
.input-date {
  input {
    position: relative;
    width: 100%;
    padding: 0.75rem;
    max-width: 100%;
    border: 0.05rem solid #e3e3e3;
    border-radius: 0.125rem !important;
    height: 2.8rem;
    background-color: #ffffff;
  }
}

.position-relative {
  position: relative;
}
</style>
