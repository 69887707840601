<template>
  <div class="p-lg">
    <h2 class="m-b-lg">{{ $t('configurator.chooseExtras') }}</h2>
    <styled-link class="m-b-lg block" href="#" @click.prevent="$emit('extras-selected', [])">
      {{ $t('configurator.nextWithoutExtras') }}
    </styled-link>
    <form>
      <card-layout :data="extras" v-slot="{ item }">
        <checkbox-card
          :id="item.id"
          :value="item.id"
          :checked="!!selectedExtras.includes(item.id)"
          @change.native="handleClickExtra"
          :title="item.Name"
          :price="formatPrice(item.Price, item.PriceType)"
          :description="item.Description"
          :iconURL="item.Icon ? item.Icon.url : iconFallback"
          :footerButtons="{ content: 'Bilder & Details ansehen' }"
          @footer-button-click="toggleModal(item)"
        />
      </card-layout>
      <div v-if="extras.length % 3 !== 0" class="card-extra" />
      <styled-button
        fullWidth
        invert
        type="button"
        @click.prevent="$emit('extras-selected', selectedExtras)"
        >{{ $t('forms.next') }}</styled-button
      >
    </form>
    <teaser-modal
      v-if="modalOpen"
      :title="modalContent.Name"
      :description="modalContent.LongDescription"
      :image="modalContent.TeaserImage"
      :gallery="modalContent.Galery"
      @close="toggleModal()"
      @submit="toggleModal()"
    />
  </div>
</template>
<script>
import teaserModal from '@/components/teaserModal.vue';
import styledButton from '@/components/common/styledButton';
import styledLink from '@/components/common/styledLink';
import cardLayout from '@/components/layout/cardLayout.vue';
import checkboxCard from '@/components/checkboxCard.vue';
import iconFallback from '@/assets/images/fallback_icon.png';
import { formatPrice } from '@/utils/price';

export default {
  name: 'extra-selection',

  components: {
    cardLayout,
    checkboxCard,
    teaserModal,
    styledButton,
    styledLink,
  },

  data() {
    return {
      iconFallback,
      modalOpen: false,
      modalContent: {},
    };
  },

  props: {
    extras: {
      type: Array,
      required: true,
    },
    selectedExtras: {
      type: Array,
      required: true,
    },
  },

  mounted() {
    window.scroll(0, 0);
  },

  methods: {
    formatPrice,

    /**
     * Changes modal state to its opposite and content for product details.
     * @param {Object} [extra] - The content for the modal
     */
    toggleModal(extra) {
      if (extra) {
        this.modalContent = extra;
        this.modalOpen = true;
      } else {
        this.modalContent = {};
        this.modalOpen = false;
      }
    },

    // was once used for the modal submit, not it's just there waiting to be used again some day
    selectExtra(item) {
      if (this.selectedExtras.includes(item)) {
        // eslint-disable-next-line vue/no-mutating-props
        this.selectedExtras.splice(this.selectedExtras.indexOf(item), 1);
        return;
      }
      // eslint-disable-next-line vue/no-mutating-props
      this.selectedExtras.push(item);
    },

    handleClickExtra(e) {
      this.selectExtra(e.target.value);
    },
  },
};
</script>
