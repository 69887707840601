import styled from 'vue-styled-components';

const selectProps = {
  width: {
    type: String,
    default: '100%',
  },
};

const styledSelect = styled('select', selectProps)`
  position: relative;
  width: 100%;
  padding: 0.75rem;
  max-width: ${props => props.width};
  border: 0.05rem solid ${props => props.theme.lightGrey};
  border-radius: 0.125rem !important;
  height: 2.8rem;
  background-color: #ffffff;
  appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'><path fill='%23444' d='M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z'></path></svg>");
  background-repeat: no-repeat;
  background-position: center right;
  font-size: 1rem;
  color: ${props => props.theme.darkGrey};
  transition: .5s ease all;

  &:focus {
    outline: none;
    border-color: ${props => props.theme.primaryColor};
  }

  &::before {
    content: "test";
    position: absolute;
    color: red;
    left: 0;
  }
`;

export { styledSelect as default };
