<template>
  <card clickable themeBackground class="category-card">
    <input
      type="radio"
      :name="name"
      :id="id"
      :value="title"
      tabindex="0"
      @click="$emit('cardSelected', id)"
      @keyup.enter="$emit('cardSelected', id)"
      class="input"
    />
    <label :for="id" class="label">
      <card-header :paddingX="showInfoButton ? '3rem' : '1.5rem'">
        <styled-circle-button
          v-show="showInfoButton"
          class="info-button"
          @click.prevent="$emit('clickInfo')">
          <icon name="info" />
        </styled-circle-button>
        <slot name="header"></slot>
      </card-header>
      <img :src="icon && icon.url || fallbackIcon" :alt="title" class="image" />
      <span class="content">
        <slot name="content"></slot>
        <styled-link
          v-show="showInfoButton"
          class="m-t-xs block link"
          inverse
          href="#"
          @click.prevent="$emit('clickInfo')">
          {{ $t("configurator.readMore") }}
        </styled-link>
      </span>
      <slot name="footer"></slot>
      <styled-button
        class="button"
        padding="0.75rem"
        font-size="1rem"
        inverted
        @click.prevent="$emit('cardSelected', id)">
        {{$t('configurator.choose')}}
      </styled-button>
    </label>
  </card>
</template>

<script>
import icon from 'vue-awesome/components/Icon.vue';
import card from '@/components/common/card';
import cardHeader from '@/components/common/cardHeader';
import styledCircleButton from '@/components/common/styledCircleButton';
import styledButton from '@/components/common/styledButton';
import styledLink from '@/components/common/styledLink';
import fallbackIcon from '@/assets/images/fallback_icon.png';

export default {
  name: 'category-card',

  components: {
    card,
    cardHeader,
    icon,
    styledCircleButton,
    styledButton,
    styledLink,
  },

  data() {
    return {
      fallbackIcon,
      checkedProxy: false,
    };
  },

  props: {
    name: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: Object,
    },
    showInfoButton: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.category-card {
  height: 100%;
  min-height: 13rem;
  display: flex;
  flex-direction: column;
}

.info-button {
  bottom: -0.5rem;
  left: 0.5rem;
  position: absolute;
  display: inline-block;
  margin-right: 0.5rem;
  background-color: #F44;
  border-color: black;
}

.input {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  &:focus {
    opacity: 1;
  }
}

.label  {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  height: 100%;
}

.image {
  background-color: white;
  object-fit: cover;
  flex-shrink: 1;
  max-height: 16rem;
  width: 100%;
}

.content {
  flex-grow: 1;
  font-size: 0.875rem;
  line-height: 1.1rem;
  padding: 1rem;
  hyphens: auto;
}

.button {
  margin: 1rem auto;
}

</style>
