<template>
  <modal @close="$emit('close')">
    <card>
      <div class="modal-teaser" :style="`background-image: url(${image ? image.url : ''}`">
        <H2 class="modal-heading" font-family="Bitter" color="#ffffff" size="2rem">
          {{title}}
        </H2>
      </div>
      <div class="modal-content">
        <div class="html-content" v-html="description" />
        <div class="modal-gallery">
          <div v-for="image in gallery" :key="image.id">
            <img :src="image.url" />
          </div>
        </div>
        <styled-button class="modal-submit" @click.prevent="$emit('submit')">
          {{ $t("configurator.back") }}
        </styled-button>
      </div>
    </card>
  </modal>
</template>
<script>
import card from '@/components/common/card';
import modal from '@/components/modal.vue';
import styledButton from '@/components/common/styledButton';
import { H2 } from '@/components/common/styledHeading';

export default {
  name: 'teaser-modal',

  components: {
    card,
    modal,
    styledButton,
    H2,
  },

  props: {
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    image: {
      type: Object,
    },
    gallery: {
      type: Array,
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-teaser {
  position: relative;
  width: 100%;
  padding-top: 11rem;
  background-size: cover;
  background-position: center center;
}

.modal-heading {
  margin: 0;
  padding: 0.5rem 0 0.5rem 2rem;
  background-color: rgba(0,0,0,0.6);
  backdrop-filter: blur(4px);
}

.modal-content {
  padding: 0.5rem 3rem 2rem;
}

.modal-gallery {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 2rem 0;

  div {
    height: 8rem;
    overflow: hidden;
    + div {
      margin-left: 1rem;
    }
  }

  img {
    object-fit: cover;
    height: 100%;
  }
}

.modal-submit {
  margin-left: auto;
}
</style>
