<template>
  <div class="modal">
    <div class="modal-content">
      <styled-circle-button
        class="modal-close"
        @click="$emit('close')">
        <icon name="times" />
      </styled-circle-button>
      <slot />
    </div>
  </div>
</template>

<script>
import icon from 'vue-awesome/components/Icon.vue';
import styledCircleButton from '@/components/common/styledCircleButton';

export default {
  name: 'modal',

  components: {
    icon,
    styledCircleButton,
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/_variables';
.modal {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.75);
  overflow: scroll;
  line-height: 1.5;
}

.modal-content {
  position: absolute;
  width: 90%;
  height: 100%;
  max-width: 40rem;
  max-height: 90vh;
  top: 10vh;
  left: 50%;
  transform: translateX(-50%);
}

.modal-close {
  position: absolute;
  z-index: 10;
  right: -1rem;
  top: -1rem;
}
</style>
