import styled, { css } from 'vue-styled-components';

const breadcrumbProps = {
  active: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: true,
  },
};

/**
 * Calculates and returns properties that change relative to the given item height.
 * @param {Number} itemHeight
 */
function calcItemProperties(itemHeight) {
  return `
    height: ${itemHeight}rem;
    padding-top: ${itemHeight / 3}rem;
    padding-right: ${itemHeight / 6}rem;
    padding-left: ${itemHeight / 1.75}rem;
    font-size: ${itemHeight / 3}rem;
  `;
}

/**
 * Calculates and returns properties that change relative to the given arrow height.
 * @param {Number} itemHeight
 */
function calcArrowProperties(itemHeight) {
  return `
    border-top: ${itemHeight / 2}rem solid transparent;
    border-bottom: ${itemHeight / 2}rem solid transparent;
  `;
}

const breadcrumb = styled('a', breadcrumbProps)`

  ${calcItemProperties(2.65)}
  position: relative;
  margin-right: 0.25rem;
  display: inline-block;
  font-weight: 700;
  text-decoration: none;
  background-color: ${({ active, theme }) => (active ? theme.primaryColor : '#E3E3E3')};
  color: ${({ active }) => (active ? '#ffffff' : '#404040')};
  cursor: pointer;

  ${({ disabled }) => disabled && css`
    color: #909090;
    pointer-events: none;
  `};

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    width: 0;
    height: 0;
    ${calcArrowProperties(2.65)};
  }

  &:before {
    left: 0;
    border-left: 1.25rem solid #ffffff;
  }

  &:after {
    z-index: 1;
    left: 100%;
    border-left: 1.25rem solid ${({ active, theme }) => (active ? theme.primaryColor : '#E3E3E3')};
  }

  &:first-child {
    &:before {
      border-left: none;
    }
  }

  &:last-child {
    margin-right: 0;
    &:after {
      border-left: none;
    }
  }

  @media screen and (min-width: 42rem) {
    ${calcItemProperties(3)}

    &:before,
    &:after {
      ${calcArrowProperties(3)};
    }
  }
`;

export { breadcrumb as default };
