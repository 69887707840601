<template>
  <div class="p-lg">
    <h2 class="m-b-lg">{{$t('configurator.chooseEventTypeLong')}}</h2>
    <form>
      <card-layout :data="eventTypes" v-slot="{ item }">
        <category-card
          name="eventType"
          :id="item.id"
          :title="item.Name"
          :icon="item.Icon"
          showInfoButton
          @clickInfo="toggleModal(item)"
          @cardSelected="$emit('eventType-selected', item.id)"
        >
          <template #header>
            <span>{{item.Name}}</span>
          </template>
          <template #content>
            <SubH center>{{ item.Subtitle }}</SubH>
            <span v-html="item.Description" />
          </template>
        </category-card>
      </card-layout>
    </form>
    <teaser-modal
      v-if="modalOpen"
      :title="modalContent.Name"
      :description="modalContent.LongDescription"
      :image="modalContent.TeaserImage"
      :gallery="modalContent.Galery"
      @close="toggleModal()"
      @submit="toggleModal()"
    />
  </div>
</template>
<script>
import cardLayout from '@/components/layout/cardLayout.vue';
import categoryCard from '@/components/categoryCard.vue';
import teaserModal from '@/components/teaserModal.vue';
import { SubH } from '@/components/common/styledHeading';

export default {
  name: 'customer-type-selection',

  components: {
    cardLayout,
    categoryCard,
    teaserModal,
    SubH,
  },

  props: {
    eventTypes: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      modalOpen: false,
      modalContent: {},
    };
  },

  methods: {
    /**
     * Changes modal state to its opposite and content for product details.
     * @param {Object} [eventType] - The content for the modal
     */
    toggleModal(eventType) {
      if (eventType) {
        this.modalContent = eventType;
        this.modalOpen = true;
      } else {
        this.modalContent = {};
        this.modalOpen = false;
      }
    },
  },
};
</script>
