<template>
  <div class="flex layout-container">
    <div class="layout-item" v-for="item in data" :key="item.id">
      <slot :item="item" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'card-layout',

  props: {
    data: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.layout-container {
  margin: 1rem -1rem;
}
.layout-item {
  width: 100%;
  padding: 1rem;

  @media screen and (min-width: 38rem) {
    width: calc(100% / 2);
  }
  @media screen and (min-width: 52rem) {
    width: calc(100% / 3);
  }
}
</style>
