<template>
  <div class="p-lg">
    <h2 class="m-b-lg">{{$t('configurator.chooseProductLong')}}</h2>
    <form>
      <card-layout :data="products" v-slot="{ item }">
        <category-card
          name="product"
          :id="item.id"
          :title="item.Name"
          :icon="item.Icon"
          showInfoButton
          @clickInfo="toggleModal(item)"
          @cardSelected="$emit('product-selected', item.id)"
        >
          <template #header>
            <span>{{item.Name}}</span>
          </template>
          <template #content>
            <SubH center>{{ $t('configurator.packageContent') }}</SubH>
            <span class="circle-bullets" v-html="item.Description" />
          </template>
          <template #footer>
            <Price>
              {{formatPrice(getPrice(item, peopleCount), item.PriceType)}}
            </Price>
          </template>
        </category-card>
      </card-layout>
    </form>
    <teaser-modal
      v-if="modalOpen"
      :title="modalContent.Name"
      :description="modalContent.LongDescription"
      :image="modalContent.TeaserImage"
      :gallery="modalContent.Galery"
      @close="toggleModal()"
      @submit="toggleModal()"
    />
  </div>
</template>
<script>
import cardLayout from '@/components/layout/cardLayout.vue';
import categoryCard from '@/components/categoryCard.vue';
import teaserModal from '@/components/teaserModal.vue';
import { SubH, Price } from '@/components/common/styledHeading';
import { getPrice, formatPrice } from '@/utils/price';

export default {
  name: 'product-selection',

  components: {
    cardLayout,
    categoryCard,
    teaserModal,
    SubH,
    Price,
  },

  props: {
    products: {
      type: Array,
      required: true,
    },
    peopleCount: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      modalOpen: false,
      modalContent: {},
    };
  },

  methods: {
    getPrice,
    formatPrice,

    /**
     * Changes modal state to its opposite and content for product details.
     * @param {String} [product] - The content for the modal
     */
    toggleModal(product) {
      if (product) {
        this.modalContent = product;
        this.modalOpen = true;
      } else {
        this.modalContent = {};
        this.modalOpen = false;
      }
    },
  },
};
</script>
<style lang="scss">
.circle-bullets {
  ul {
    list-style-type: circle;
  }
}
</style>
