<template>
  <div class="p-lg">
    <h2 class="m-b-lg">{{$t('configurator.chooseCustomerTypeLong')}}</h2>
    <form>
      <card-layout :data="customerTypes" v-slot="{ item }">
        <category-card
          name="customerType"
          :id="item.id"
          :title="item.Name"
          :icon="item.Icon"
          @cardSelected="$emit('customerType-selected', item.id)"
        >
          <template #header>
            {{$t('configurator.chooseCustomerTypeHeading')}}
            <span>{{item.Name}}</span>
          </template>
          <template #content>
            <span v-html="item.Description" />
          </template>
        </category-card>
      </card-layout>
    </form>
  </div>
</template>
<script>
import cardLayout from '@/components/layout/cardLayout.vue';
import categoryCard from '@/components/categoryCard.vue';

export default {
  name: 'customer-type-selection',

  components: {
    cardLayout,
    categoryCard,
  },

  props: {
    customerTypes: {
      type: Array,
      required: true,
    },
  },
};
</script>
