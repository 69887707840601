import styled from 'vue-styled-components';

const linkProps = {
  inverse: {
    type: Boolean,
    default: false,
  },
};

const styledLink = styled('a', linkProps)`
  text-decoration: none;
  color: ${props => (props.inverse ? props.theme.secondaryColor : props.theme.primaryColor)};

  &:visited, &:hover {
    color: ${props => (props.inverse ? props.theme.secondaryColor : props.theme.primaryColor)};
  }
`;

export { styledLink as default };
