<template>
  <div class="container">
    <heading-with-button backTwice />
    <div class="p-xxl bg-white">
      <H2 font-family="Bitter" center>{{$t('configurator.thankYou')}}</H2>
      <p class="text-center">{{$t('configurator.thankYouText')}}</p>
    </div>
  </div>
</template>
<script>
import { H2 } from '@/components/common/styledHeading';
import headingWithButton from '@/components/headingWithButton.vue';

export default {
  components: {
    H2,
    headingWithButton,
  },
};
</script>
