<template>
  <div class="header-wrapper">
    <H1 font-family="Open Sans" font-weight="bold">
      {{heading}}
    </H1>
    <styled-button @click="historyBack">
      {{$t('configurator.back')}}
    </styled-button>
  </div>
</template>

<script>
import { H1 } from '@/components/common/styledHeading';
import styledButton from '@/components/common/styledButton';

export default {
  name: 'heading-with-button',

  components: {
    styledButton,
    H1,
  },

  props: {
    heading: {
      type: String,
    },
    backTwice: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    historyBack() {
      window.history.go(this.backTwice ? -2 : -1);
    },
  },
};
</script>

<style lang="scss" scoped>
.header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}
</style>
