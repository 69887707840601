import styled from 'vue-styled-components';

const buttonProps = {
  size: {
    type: String,
    default: '2rem',
  },
};

const styledCircleButton = styled('button', buttonProps)`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid ${props => props.theme.lightGrey};
  border-radius: 50%;
  width: ${props => props.size};
  height: ${props => props.size};
  transition: .5s ease;

  &:focus {
    outline: none;
  }

  svg {
    width: 1rem;
    height: 1rem;
  }
`;

export { styledCircleButton as default };
