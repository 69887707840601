<template>
  <card
    clickable
    class="card-product"
  >
    <checkbox
      :class="`input ${footerButtons ? '--with-footer' : ''}`"
      type="checkbox"
      :id="id"
      :value="value"
      :checked="checked"
      @change="onChange"
    />
    <label
      :for="id"
      class="label"
    >
      <div class="label-image-container">
        <img
          :src="iconURL"
          class="label-image"
          :alt="title"
        />
      </div>
      <div :class="`label-content ${footerButtons ? '--with-footer' : ''}`">
        <div class="label-description">
          <H2>{{title}}</H2>
          <p v-html="description" />
        </div>
        <span class="label-price">{{price}}</span>
      </div>
    </label>
    <div v-if="footerButtons" class="button-footer">
      <styled-button
        full-width
        padding="0.75rem"
        font-size="1rem"
        inverted
        @click.prevent="$emit('footer-button-click')"
      >
        {{footerButtons.content}}
      </styled-button>
    </div>
  </card>
</template>

<script>
import styledButton from '@/components/common/styledButton';
import { H2 } from '@/components/common/styledHeading';
import checkbox from '@/components/common/checkbox';
import card from '@/components/common/card';

export default {
  name: 'checkbox-card',

  components: {
    card,
    checkbox,
    H2,
    styledButton,
  },

  data() {
    return {
      checkedProxy: false,
    };
  },

  props: {
    iconURL: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    price: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    footerButtons: {
      type: Object,
      default: () => {},
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    onChange(e) {
      this.$emit('input', e.target.checked);
    },
  },
};
</script>

<style lang="scss" scoped>

.card-product {
  position: relative;
  height: 100%;
}

.input {
  position: absolute;
  cursor: pointer;
  bottom: 1rem;
  left: 1rem;

  &.--with-footer {
    bottom: 3.25rem;

    @media screen and (min-width: 38rem) {
      bottom: 4rem;
    }
  }

}

.label  {
  display: flex;
  cursor: pointer;
  padding: 0.75rem;
  width: 100%;
  height: 100%;

  @media screen and (min-width: 38rem) {
    padding: 1.5rem 1rem;
  }

  &-image {
    width: 3rem;
    margin-right: 0.25rem;
    margin-bottom: 0.25rem;
  }

  &-content {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    font-size: 0.8rem;
    line-height: 1.1rem;
    hyphens: auto;

    @media screen and (min-width: 38rem) {
      font-size: 0.875rem;
      line-height: 1.2rem;
    }

    &.--with-footer {
      padding-bottom: 3.2rem;
    }
  }

  &-price {
    display: inline-block;
    margin-top: 1rem;
    font-weight: bold;
    font-size: 1rem;
    align-self: flex-end;
  }
}

.button-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

</style>
