<template>
  <div class="container">
    <breadcrumb
      v-for="(item, index) in items"
      :key="item.id"
      :active="item.id === currentItem"
      :disabled="index > enabledSteps && index !== items.length - 1"
      :href="item.link ? item.link : undefined"
      :title="item.name"
      @click="$emit('item-click', item.id)">
      {{item.name}}
    </breadcrumb>
  </div>
</template>

<script>
import breadcrumb from './breadcrumb';

export default {
  name: 'breadcrumbs',

  components: {
    breadcrumb,
  },

  props: {
    items: {
      type: Array,
      required: true,
    },

    currentItem: {
      type: String,
      required: true,
    },

    enabledSteps: {
      type: Number,
      required: true,
    },
  },

};
</script>

<style lang="scss" scoped>
.container {
  box-shadow: 0 2px 15px 0 rgba(0,0,0,0.15);
}
</style>
